import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormControl,
  Validators,
  NonNullableFormBuilder,
  FormGroup,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { AppData } from "../../../shared/models/appData";
import { Partner } from "../../../shared/models/partner";
import {
  CustomFormValidators,
  emailListValidator,
  noDuplicatesValidator,
} from "../../../shared/validators/custom-form-validators";
import { cloneWith } from "../../../shared/services/clonable";

interface PartnerProfileForm {
  title: FormControl<string>;
  contactPersonFirstName: FormControl<string>;
  contactPersonLastName: FormControl<string>;
  emails: FormControl<string[]>;
  prefixPhone: FormControl<string>;
  phone: FormControl<string>;
  prefixPhoneSecond: FormControl<string>;
  phoneSecond: FormControl<string>;
  isContactCustomerData: FormControl<boolean>;
  sendCustomerDataEmail: FormControl<string>;
}

@Component({
  selector: "app-partner-profile-contact-information-form",
  templateUrl: "./partner-profile-contact-information-form.component.html",
  styleUrl: "./partner-profile-contact-information-form.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerProfileContactInformationFormComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) public partner!: Partner;
  @Input({ required: true }) public appData!: AppData;
  @Input({ required: true }) public isEditForm!: boolean;
  @Input() public isLoading = false;

  @Output() public cancel = new EventEmitter<void>();
  @Output() public formSubmit = new EventEmitter<Partner>();

  protected form!: FormGroup<PartnerProfileForm>;

  private readonly subscriptions = new Subscription();

  constructor(private readonly fb: NonNullableFormBuilder) {}

  public ngOnInit(): void {
    const sendCustomerDataEmailValidators = [CustomFormValidators.email];

    this.form = this.fb.group({
      title: [this.partner.contactPersonTitle, Validators.required],
      contactPersonFirstName: [
        this.partner.contactPersonFirstName,
        Validators.required,
      ],
      contactPersonLastName: [
        this.partner.contactPersonLastName,
        Validators.required,
      ],
      emails: [
        this.partner.emails,
        [Validators.required, emailListValidator(), noDuplicatesValidator()],
      ],
      prefixPhone: [this.partner.prefixPhone, Validators.required],
      phone: [
        this.partner.phone,
        [Validators.required, CustomFormValidators.number],
      ],
      prefixPhoneSecond: [this.partner.prefixPhoneSecond],
      phoneSecond: [this.partner.phoneSecond, [CustomFormValidators.number]],
      isContactCustomerData: [
        this.partner.isContactCustomerData ?? true,
        Validators.required,
      ],
      sendCustomerDataEmail: [
        this.partner.sendCustomerDataEmail,
        !(this.partner.isContactCustomerData ?? true)
          ? sendCustomerDataEmailValidators.concat(Validators.required)
          : sendCustomerDataEmailValidators,
      ],
    });

    this.subscriptions.add(
      this.form
        .get("isContactCustomerData")!
        .valueChanges.subscribe((value) => {
          const sendCustomerDataEmail = this.form.get("sendCustomerDataEmail")!;

          sendCustomerDataEmail.setValidators(
            value
              ? sendCustomerDataEmailValidators
              : sendCustomerDataEmailValidators.concat(Validators.required),
          );

          sendCustomerDataEmail.updateValueAndValidity();
        }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected getControl<T extends keyof PartnerProfileForm>(
    control: T,
  ): PartnerProfileForm[T] {
    return this.form.get(control) as unknown as PartnerProfileForm[T];
  }

  protected onActionCancel(): void {
    this.cancel.emit();
  }

  protected onActionSubmit(): void {
    this.formSubmit.emit(cloneWith(this.partner, this.form.getRawValue()));
  }
}
