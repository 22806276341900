<form
  name="company-information-form"
  #formElement="ngForm"
  (ngSubmit)="onSubmit(formElement.form, $event)"
  [ngClass]="{ loading: isLoading }"
  *ngIf="partner"
  novalidate
>
  <section class="section">
    <div class="container">
      <div class="row valign-center direction-down">
        <div class="col width-100 hack-max-width">
          <h2 class="section-title">
            {{ "partner.edit.companyInformation" | translate }}
          </h2>
          <mat-form-field [hidden]="true">
            <mat-label>{{
              "partner.edit.slugPlaceholder" | translate
            }}</mat-label>
            <input
              matInput
              disabled
              #slug="ngModel"
              [(ngModel)]="partner.slug"
              name="slug"
              required
            />
            <mat-hint>{{ "partner.edit.slugHint" | translate }}</mat-hint>
          </mat-form-field>
          <div class="row">
            <div class="col width-50">
              <mat-form-field>
                <mat-label>{{
                  "partner.edit.companyPlaceholder" | translate
                }}</mat-label>
                <input
                  matInput
                  #companyName="ngModel"
                  [(ngModel)]="partner.companyName"
                  (input)="companyNameChanged()"
                  name="companyName"
                  required
                  data-test="company-name"
                />
                <mat-hint>
                  {{ "partner.edit.companyHint" | translate }}
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col width-30">
              <mat-form-field>
                <mat-label>{{
                  "partner.edit.vatPlaceholder" | translate
                }}</mat-label>
                <input
                  matInput
                  #vatNumber="ngModel"
                  [(ngModel)]="partner.vatNumber"
                  name="vatNumber"
                  required
                  data-test="vat-number"
                />
                <mat-error>{{
                  "partner.edit.vatIsRequired" | translate
                }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col width-20">
              <mat-form-field>
                <mat-label>{{ "shared.currency" | translate }}</mat-label>
                <mat-select
                  [(ngModel)]="partner.currency"
                  name="currency"
                  required
                >
                  <mat-option
                    *ngFor="let currency of currencies"
                    [value]="currency.code"
                  >
                    {{ currency.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>{{
                  "partner.edit.storeName" | translate
                }}</mat-label>
                <input
                  maxlength="{{ COMPANY_ADDITIONAL_INFO_MAX_LENGTH }}"
                  matInput
                  #companyAdditionalInformation="ngModel"
                  [(ngModel)]="partner.companyAdditionalInformation"
                  name="companyAdditionalInformation"
                  required
                  data-test="store-name"
                />
                <mat-hint>
                  {{
                    "partner.edit.storeNameSubTitle"
                      | translate
                        : { maxlength: COMPANY_ADDITIONAL_INFO_MAX_LENGTH }
                  }}
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col width-100">
              <mat-form-field>
                <mat-label>{{
                  "partner.address.addressPlaceholder" | translate
                }}</mat-label>
                <input
                  matInput
                  #addressStreet="ngModel"
                  [(ngModel)]="billingAddress.streetAndNumber"
                  name="street"
                  required
                  data-test="street"
                />
                <mat-error>{{
                  "partner.address.addressRequired" | translate
                }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col width-50">
              <mat-form-field>
                <mat-label>{{
                  "partner.address.zipCodePlaceholder" | translate
                }}</mat-label>
                <input
                  matInput
                  #addressPostalCode="ngModel"
                  [(ngModel)]="billingAddress.postalCode"
                  name="postalCode"
                  required
                  data-test="postal-code"
                />
                <mat-error>{{
                  "partner.address.zipCodeRequired" | translate
                }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col width-50">
              <mat-form-field>
                <mat-label>{{
                  "partner.address.cityPlaceholder" | translate
                }}</mat-label>
                <input
                  matInput
                  #addressCity="ngModel"
                  [(ngModel)]="billingAddress.city"
                  name="city"
                  required
                  data-test="city"
                />
                <mat-error>{{
                  "partner.address.cityRequired" | translate
                }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col width-50">
              <app-country-selector
                [(value)]="billingAddress.country"
                name="country"
                placeholder="{{
                  'partner.address.countryPlaceholder' | translate
                }}"
                [required]="true"
              />
            </div>
            <div class="col width-50">
              <mat-form-field *ngIf="sectors">
                <mat-label>{{
                  "partner.edit.sectorPlaceholder" | translate
                }}</mat-label>
                <mat-select
                  [(ngModel)]="partner.sectors"
                  name="sector"
                  multiple
                >
                  <mat-option *ngFor="let sector of sectors" [value]="sector">
                    {{ sector.name | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field *ngIf="languages">
                <mat-label>{{
                  "partner.edit.languagePlaceholder" | translate
                }}</mat-label>
                <mat-select [(ngModel)]="partner.language" name="language">
                  <mat-option
                    *ngFor="let language of languages"
                    [value]="language.code"
                  >
                    {{ language.name | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <mat-form-field>
            <mat-label>{{
              "partner.address.additionalInformationPlaceholder" | translate
            }}</mat-label>
            <textarea
              class="address-additional-information"
              matInput
              maxlength="255"
              #addressAdditionalInformation="ngModel"
              [(ngModel)]="billingAddress.additionalInformation"
              name="additionalInformation"
              data-test="additional-info"
            >
            </textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </section>
  <section
    class="section"
    [ngClass]="{ 'section--collapsed': isShippingAddressSame }"
  >
    <div class="container">
      <div class="row valign-center direction-down">
        <div class="col width-50">
          <div class="section-header">
            <h2 class="section-title">
              <ng-container>{{
                "partner.edit.shippingAddress" | translate
              }}</ng-container>
              <app-tooltip
                text="{{ 'partner.edit.shippingAddressToolTip' | translate }}"
              />
            </h2>
            <mat-checkbox
              #isShippingAddressSameCheckbox
              [(ngModel)]="isShippingAddressSame"
              name="isShippingAddressSame"
              (change)="toggleShippingAddress()"
              [checked]="true"
            >
              {{ "partner.edit.shippingAddressSame" | translate }}
            </mat-checkbox>
          </div>
          <ng-container *ngIf="!isShippingAddressSame">
            <app-shipping-address [(address)]="shippingAddress" />
          </ng-container>
        </div>
      </div>
    </div>
  </section>
  <section class="section">
    <div class="container">
      <div class="row valign-center direction-down">
        <div class="col width-50">
          <div class="button-bar">
            <button
              class="button-previous round-button round-button--grey"
              type="button"
              (click)="cancel.emit()"
            >
              {{ "shared.cancel" | translate }}
            </button>
            <button
              class="button-next round-button round-button--orange"
              [disabled]="!isSubmitEnabled(formElement.form)"
            >
              {{ "shared.next" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</form>
