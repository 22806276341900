<form
  name="company-information-form"
  #form="ngForm"
  (ngSubmit)="onActionSubmit()"
  *ngIf="partner"
  novalidate
>
  <div class="container">
    <section class="section">
      <div class="form-container">
        <div class="row">
          <div class="col width-50 width-70-small">
            <h2 class="section-title">
              {{ "partner.edit.companyInformation" | translate }}
            </h2>
            <mat-form-field class="fieldset">
              <mat-label>{{
                "partner.edit.slugPlaceholder" | translate
              }}</mat-label>
              <input
                matInput
                disabled
                #slug="ngModel"
                [(ngModel)]="partner.slug"
                name="slug"
                required
              />
              <mat-hint>{{ "partner.edit.slugHint" | translate }}</mat-hint>
            </mat-form-field>
            <div class="row">
              <div class="col width-45">
                <mat-form-field>
                  <mat-label>{{
                    "partner.edit.companyPlaceholder" | translate
                  }}</mat-label>
                  <input
                    matInput
                    #companyName="ngModel"
                    [(ngModel)]="partner.companyName"
                    [errorStateMatcher]="errorStateMatcher"
                    name="companyName"
                    required
                  />
                  <mat-hint *ngIf="!companyName.errors">
                    {{ "partner.edit.companyHint" | translate }}
                  </mat-hint>
                  <mat-error>{{
                    "partner.edit.companyRequired" | translate
                  }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col width-30">
                <mat-form-field>
                  <mat-label>{{
                    "partner.edit.vatPlaceholder" | translate
                  }}</mat-label>
                  <input
                    matInput
                    #vatNumber="ngModel"
                    [(ngModel)]="partner.vatNumber"
                    name="vatNumber"
                    required
                  />
                  <mat-error>{{
                    "shared.requiredField" | translate
                  }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col width-25">
                <mat-form-field class="fieldset">
                  <mat-label>{{
                    "partner.edit.currencyPlaceholder" | translate
                  }}</mat-label>
                  <mat-select
                    [(ngModel)]="partner.currency"
                    name="currency"
                    required
                  >
                    <mat-option
                      *ngFor="let currency of currencies"
                      [value]="currency.code"
                    >
                      {{ currency.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col width-60">
                <mat-form-field *ngIf="storeNameControl">
                  <mat-label>{{
                    "partner.edit.storeName" | translate
                  }}</mat-label>
                  <input
                    maxlength="{{ COMPANY_ADDITIONAL_INFO_MAX_LENGTH }}"
                    matInput
                    [formControl]="storeNameControl"
                    [errorStateMatcher]="errorStateMatcher"
                    name="companyAdditionalInformation"
                  />
                  <mat-hint *ngIf="!storeNameControl.errors">
                    {{
                      "partner.edit.storeNameSubTitle"
                        | translate
                          : { maxlength: COMPANY_ADDITIONAL_INFO_MAX_LENGTH }
                    }}
                  </mat-hint>
                  <mat-error *ngIf="storeNameControl.errors?.required">
                    {{ "shared.required" | translate }}
                  </mat-error>
                  <mat-error
                    *ngIf="storeNameControl.errors?.twoConsecutiveDots"
                  >
                    {{ "shared.errorTwoDotsPattern" | translate }}
                  </mat-error>
                  <mat-error *ngIf="storeNameControl.errors?.uppercaseLimit">
                    {{
                      "brand.campaign.content.googleAdCapitalizeWarning"
                        | translate
                    }}
                  </mat-error>
                  <mat-error *ngIf="storeNameControl.errors?.maxlength">
                    {{
                      "shared.errorMaxLength"
                        | translate
                          : { maxlength: COMPANY_ADDITIONAL_INFO_MAX_LENGTH }
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col width-40">
                <mat-form-field *ngIf="sectors">
                  <mat-label>{{
                    "partner.edit.sectorPlaceholder" | translate
                  }}</mat-label>
                  <mat-select
                    [compareWith]="compareWithSector"
                    [(ngModel)]="partner.sectors"
                    name="sector"
                    multiple
                  >
                    <mat-option
                      *ngFor="let sector of sectors | sort: 'name'"
                      [value]="sector"
                    >
                      {{ sector.name | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field *ngIf="languages">
                  <mat-label>{{
                    "partner.edit.languagePlaceholder" | translate
                  }}</mat-label>
                  <mat-select [(ngModel)]="partner.language" name="language">
                    <mat-option
                      *ngFor="let language of languages | sort: 'name'"
                      [value]="language.code"
                    >
                      {{ language.name | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <!-- Addresses container -->
        <div class="row">
          <div class="col width-100">
            <div class="addresses">
              <div class="address">
                <h2 class="section-title">
                  <ng-container>{{
                    "partner.edit.billingAddress" | translate
                  }}</ng-container>
                </h2>
                <app-address-slider
                  *ngIf="partner && countries()"
                  [partner]="partner"
                  [countries]="countries()"
                  [addressType]="AddressType.Billing"
                />
              </div>
              <div class="new-address-title">
                <a (click)="createAddress(AddressType.Billing)">
                  {{ "partner.edit.addBillingAddress" | translate }}
                  <img height="32" src="/assets/images/new-address-icon.svg" />
                </a>
              </div>
              <div class="address">
                <h2 class="section-title">
                  <ng-container>{{
                    "partner.edit.storeLocation" | translate
                  }}</ng-container>
                </h2>
                <app-address-slider
                  *ngIf="partner && countries()"
                  [partner]="partner"
                  [countries]="countries()"
                  [addressType]="AddressType.Shipping"
                />
              </div>
              <div class="new-address-title">
                <a (click)="createAddress(AddressType.Shipping)">
                  {{ "partner.edit.addStoreLocation" | translate }}
                  <img height="32" src="/assets/images/new-address-icon.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="form-container">
        <div class="row">
          <div class="col width-50">
            <div class="button-bar">
              <button
                class="button-next round-button round-button--orange"
                type="submit"
                [disabled]="submitting"
              >
                {{ "shared.save" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</form>
