<form
  class="add-email-form"
  name="user-management-form"
  (ngSubmit)="umForm.form.valid && addUser(usrEmail.value)"
  #umForm="ngForm"
  novalidate
>
  <section class="section">
    <div class="container">
      <div
        class="row"
        [ngClass]="{ 'valign-center direction-down': !isEditForm }"
      >
        <div class="col width-70">
          <h2 class="section-title">
            {{ "userManagement.title" | translate }}
          </h2>
          <div class="row">
            <div class="col width-60">
              <mat-form-field class="full-width">
                <mat-label>{{ "shared.email" | translate }}</mat-label>
                <input
                  matInput
                  #usrEmail="ngModel"
                  [(ngModel)]="userEmail"
                  name="usrEmail"
                  type="email"
                  email
                  required
                />
                <mat-error
                  *ngIf="usrEmail.errors && usrEmail.errors['required']"
                >
                  {{ "shared.emailAddressIsRequired" | translate }}
                </mat-error>
                <mat-error
                  *ngIf="
                    usrEmail.errors &&
                    usrEmail.errors['email'] &&
                    !usrEmail.errors.required
                  "
                >
                  {{ "shared.enterAValidEmailAddress" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col width-20">
              <button
                [disabled]="saving || !userEmail"
                class="button-grey full-width"
              >
                {{ (saving ? "shared.saving" : "shared.add") | translate }}
              </button>
            </div>
          </div>
          <p class="section-intro">
            {{ "userManagement.intro" | translate }}
          </p>
          <ul class="email-list" *ngIf="partner">
            <li class="email-item-container" *ngFor="let user of partner.users">
              <div
                class="email-item"
                [ngClass]="{
                  'email-item--disabled': user.email === loggedInUserName
                }"
              >
                <div>
                  {{ user.email }}
                  <span *ngIf="user.email === loggedInUserName">
                    (<ng-container>{{
                      "userManagement.yourself" | translate
                    }}</ng-container
                    >)
                  </span>
                  <span *ngIf="user.email !== loggedInUserName">
                    ({{ "userManagement." + user.status | translate }})
                  </span>
                </div>
                <button class="btn--image-only">
                  <img
                    class="email-item__delete"
                    src="/assets/images/icons/ic_remove.svg"
                    (click)="confirmRemoval(user)"
                    alt=""
                  />
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="!isEditForm">
      <div
        class="row"
        [ngClass]="{ 'valign-center direction-down': !isEditForm }"
      >
        <div class="col width-50">
          <div class="button-bar">
            <button
              class="round-button round-button--grey button-previous"
              type="button"
              (click)="onCancel()"
            >
              {{ "shared.previous" | translate }}
            </button>
            <button
              class="round-button round-button--orange next-button"
              type="button"
              (click)="onFinish()"
            >
              {{ "shared.finish" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</form>
