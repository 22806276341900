<app-partner-profile-progress *ngIf="partner" [partner]="partner" />
<div class="edit-partner-container" *ngIf="partner">
  <mat-tab-group [(selectedIndex)]="selectedIndex" mat-stretch-tabs="false">
    <!-- Company Info Tab -->
    <mat-tab label="{{ 'partner.edit.companyInfoTab' | translate }}">
      <app-partner-profile-company-information-form
        *ngIf="appData && partner"
        [partner]="partner"
        [appData]="appData"
        [submitting]="submitting"
        (formSubmit)="submit()"
      />
    </mat-tab>
    <!-- Contact Info Tab -->
    <mat-tab label="{{ 'partner.edit.contactInfoTab' | translate }}">
      <app-partner-profile-contact-information-form
        *ngIf="appData && partner"
        [partner]="partner"
        [appData]="appData"
        [isEditForm]="true"
        (formSubmit)="submit($event)"
      />
    </mat-tab>
    <!-- Social Accounts Tab -->
    <mat-tab label="{{ 'partner.edit.socialAccountsTab' | translate }}">
      <app-partner-profile-social-links-form
        [partner]="partner"
        [isEditForm]="true"
        (formSubmit)="submit()"
      />
    </mat-tab>
    <!-- Company files Tab -->
    <mat-tab label="{{ 'partner.edit.companyFilesTab' | translate }}">
      <app-partner-profile-company-files-form
        [partner]="partner"
        [isEditForm]="true"
      />
    </mat-tab>
    <!-- User Management Tab -->
    <mat-tab
      *ngIf="!isWhiteLabel"
      label="{{ 'partner.edit.userManagementTab' | translate }}"
    >
      <app-user-management-form [partner]="partner" [isEditForm]="true" />
    </mat-tab>
  </mat-tab-group>
</div>
