import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import isEqual from "lodash/isEqual";
import { PartnerDialogService } from "../../partner-dialog.service";
import { CanComponentDeactivate } from "../../../shared/guards/can-deactivate.guard";
import { Partner } from "../../../shared/models/partner";
import { PartnerService } from "../../../shared/services/api/partner.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { EmailValidatorService } from "../../../shared/services/email-validator-service";
import { isWhiteLabel } from "../../../shared/services/api/authentication.service";
import { AppDataService } from "../../../shared/services/api/app-data.service";
import { AppData } from "../../../shared/models/appData";
import { cloneWith } from "../../../shared/services/clonable";

enum PartnerEditionStep {
  Company,
  Contact,
  Social,
  Logo,
}

@Component({
  selector: "app-edit-partner",
  templateUrl: "./edit-partner.component.html",
  styleUrl: "./edit-partner.component.scss",
})
export class EditPartnerComponent
  implements OnInit, OnDestroy, CanComponentDeactivate
{
  protected readonly isWhiteLabel = isWhiteLabel();
  protected appData!: AppData;
  protected partner!: Partner;
  protected selectedIndex = 0;
  protected submitting = false;

  private readonly subscriptions = new Subscription();
  private originalPartner?: Partner;
  private readonly partnerFormProperties: (keyof Partner)[] = [
    "companyName",
    "vatNumber",
    "currency",
    "companyAdditionalInformation",
    "language",
    "contactPersonFirstName",
    "contactPersonGender",
    "contactPersonLastName",
    "phone",
    "phoneSecond",
    "twitter",
    "instagram",
    "blogUrl",
    "other",
    "frequencyDistribution",
    "numSubscribers",
    "offerNewsletterSubscription",
  ];

  constructor(
    private readonly appDataService: AppDataService,
    private readonly dialogService: PartnerDialogService,
    private readonly notificationService: NotificationService,
    private readonly partnerService: PartnerService,
    private readonly route: ActivatedRoute,
    private readonly validatorService: EmailValidatorService,
  ) {}

  public ngOnInit(): void {
    this.fetchCurrentPartner();
    this.partner = this.partnerService.currentPartner;

    this.appDataService.get().then((appData) => {
      this.appData = appData;
    });

    this.subscriptions.add(
      this.partnerService.currentPartner$.subscribe((partner) => {
        if (partner) {
          this.partner = partner.clone();
        }
      }),
    );

    this.subscriptions.add(
      this.route.queryParams.subscribe((params) => {
        if (params.step in PartnerEditionStep) {
          this.selectedIndex = +PartnerEditionStep[params.step];
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  private fetchCurrentPartner(): void {
    this.partnerService.fetchCurrentPartner().subscribe({
      next: (result) => {
        if (result) {
          this.partner = result;
          this.originalPartner = this.pick(
            this.partner,
            this.partnerFormProperties,
          );
        }
      },
      error: () =>
        this.notificationService.error("shared.errorLoadingCurrentPartner"),
    });
  }

  private pick(obj: Partner, keys: (keyof Partner)[]): Partner | undefined {
    if (!obj) {
      return;
    }
    console.info(obj);
    return cloneWith(
      new Partner(),
      keys
        .map((k) => (k in obj ? { [k]: obj[k] } : {}))
        .reduce((res, o) => Object.assign(res, o), {}),
    );
  }

  @HostListener("window:beforeunload", ["$event"])
  public unloadNotification($event: BeforeUnloadEvent): void {
    if (!this.partner) {
      return;
    }

    if (
      !isEqual(
        this.pick(this.partner, this.partnerFormProperties),
        this.originalPartner,
      )
    ) {
      $event.returnValue = true;
    }
  }

  public async canDeactivate(): Promise<boolean> {
    if (!this.partner || !this.originalPartner) {
      return false;
    }

    if (
      !isEqual(
        this.pick(this.partner, this.partnerFormProperties),
        this.originalPartner,
      )
    ) {
      return this.dialogService.showLeave().then((result) => result ?? false);
    }

    return true;
  }

  protected submit(updatedPartner?: Partner): void {
    if (updatedPartner) {
      this.partner = updatedPartner;
    }

    if (
      (this.partner.emails && this.partner.emails.length === 0) ||
      !this.validateEmails(this.partner.emails)
    ) {
      this.notificationService.error("partner.edit.atLeastOneEmailRequired");
      return;
    }
    this.submitting = true;
    this.partnerService.update(this.partner).subscribe({
      next: (partner) => {
        this.originalPartner = this.pick(
          this.partner,
          this.partnerFormProperties,
        );
        this.partnerService.setCurrentPartner(partner);
        this.partner.addresses = partner.addresses;
        this.notificationService.success("shared.changesSavedSuccessfully");
        this.partnerService.profileProgressUpdated.emit();
        this.submitting = false;
      },
      error: () => {
        this.submitting = false;
        this.notificationService.error("shared.changesCouldNotBeSaved");
      },
    });
  }

  private validateEmails(emails: string[]): boolean {
    return this.validatorService.validateEmails(emails);
  }
}
