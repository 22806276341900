import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import dayjs from "dayjs";
import { lastValueFrom, Subscription } from "rxjs";
import {
  CampaignRequiredAction,
  SortByDateMode,
} from "../../../shared/enums/campaign.enums";
import { Partner } from "../../../shared/models/partner";
import {
  PartnerCampaign,
  PartnerCampaignStatus,
  PartnerCampaignStatusAttrs,
} from "../../../shared/models/partnerCampaign";
import { AuthenticationService } from "../../../shared/services/api/authentication.service";
import {
  PartnerCampaignService,
  PartnerCampaignStatusFilter,
} from "../../../shared/services/api/partner-campaign.service";
import { PartnerService } from "../../../shared/services/api/partner.service";
import { DialogService } from "../../../shared/services/dialog.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { PartnerUrl } from "../../partner.url";
import { valueAscOrder } from "../../../shared/utils/value-asc-order";
import { PartnerDialogService } from "../../partner-dialog.service";

@Component({
  selector: "app-partner-campaign-list",
  templateUrl: "./partner-campaign-list.component.html",
  styleUrl: "./partner-campaign-list.component.scss",
})
export class PartnerCampaignListComponent implements OnInit, OnDestroy {
  protected readonly CAMPAIGN_STATUS_OPTIONS = PartnerCampaignStatusAttrs;
  protected readonly LIMIT = 24;
  protected readonly SORT_MODES = SortByDateMode;
  protected readonly valueAscOrder = valueAscOrder;

  protected loading = false;
  protected loadingError = false;
  protected loadingFirstTime = true;
  protected showFilter = false;
  protected shownCampaigns: PartnerCampaign[] = [];
  protected sortMode = SortByDateMode.Desc;
  protected totalCampaigns = 0;
  protected totalInitialCampaigns = 0;

  private readonly filterByBrandId?: number;
  private readonly partner: Partner;
  private readonly subs = new Subscription();
  private campaigns: PartnerCampaign[] = [];
  private offset = 0;
  private statusFilter: PartnerCampaignStatusFilter = {};

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly dialogService: DialogService,
    private readonly notificationService: NotificationService,
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly partnerDialogService: PartnerDialogService,
    private readonly partnerService: PartnerService,
    private readonly router: Router,
  ) {
    this.filterByBrandId = this.authenticationService.whiteLabelBrandId;
    this.partner = this.partnerService.currentPartner;
  }

  public async ngOnInit(): Promise<void> {
    await this.onViewAllCampaigns();
    this.getCampaigns();
  }

  public ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  protected onPageChange(event: PageEvent): void {
    this.offset = this.LIMIT * event.pageIndex;
    this.getCampaigns();
  }

  protected async getCampaigns(): Promise<void> {
    this.loading = true;

    try {
      const campaigns = await lastValueFrom(
        this.partnerCampaignService.getPartnerCampaigns(
          this.offset,
          this.LIMIT,
          this.sortMode.toUpperCase(),
          this.statusFilter,
          this.filterByBrandId ?? null,
        ),
      );

      this.campaigns = campaigns.items;
      this.totalCampaigns = campaigns.totalCount;
      this.shownCampaigns = this.campaigns;
      this.loadingError = false;

      if (!this.totalInitialCampaigns) {
        this.totalInitialCampaigns = campaigns.totalCount;
      }
    } catch {
      this.loadingError = true;
      this.notificationService.error(
        "partner.campaignList.errorLoadingCampaigns",
      );
    } finally {
      this.loading = false;
      this.loadingFirstTime = false;
    }
  }

  protected handleRequiredAction(
    campaign: PartnerCampaign,
    action: CampaignRequiredAction,
  ): void {
    switch (action) {
      case CampaignRequiredAction.AcceptInvitation:
        this.partnerAcceptCampaignConfirm(campaign);
        break;

      case CampaignRequiredAction.AddConnection:
        this.partnerAddConnection(campaign);
        break;

      case CampaignRequiredAction.BudgetLeft:
        this.router.navigateByUrl(
          PartnerUrl.CampaignContentCalendar(this.partner.id, campaign.id),
        );
        break;

      case CampaignRequiredAction.ShowResults:
        this.goToPartnerDashboard(campaign);
        break;

      case CampaignRequiredAction.StartCampaign:
        this.router.navigateByUrl(
          PartnerUrl.CampaignContentCalendar(this.partner.id, campaign.id),
        );
        break;
    }
  }

  private partnerAcceptCampaignConfirm(campaign: PartnerCampaign): void {
    this.dialogService.confirm({
      title: "partner.campaignList.joinCampaignConfirmTitle",
      message: "partner.campaignList.joinCampaignConfirm",
      onConfirm: () => this.partnerAcceptCampaign(campaign),
    });
  }

  private partnerAcceptCampaign(campaign: PartnerCampaign) {
    this.partnerCampaignService
      .partnerAcceptCampaign(campaign.id)
      .subscribe(() => {
        this.getCampaigns();
        this.notificationService.success(
          "partner.campaignList.campaignJoinedSuccessfully",
        );
      });
  }

  private async partnerAddConnection(campaign: PartnerCampaign): Promise<void> {
    const partnerCampaign = await lastValueFrom(
      this.partnerCampaignService.loadPartnerCampaign(campaign.id),
    );

    const isConnectionFinished =
      await this.partnerDialogService.showPartnerCampaignListAddConnection({
        campaign: partnerCampaign,
        partner: this.partner,
      });

    if (isConnectionFinished) {
      this.getCampaigns();
    }
  }

  protected addPartnerToCampaign(form: NgForm): void {
    this.partnerService
      .addPartnerToCampaign(this.partner.id, form.value)
      .subscribe({
        next: () => {
          this.notificationService.success(
            "partner.campaignList.campaignJoinedSuccessfully",
          );
          this.getCampaigns();
          form.reset();
        },
        error: (err) => {
          const errorArray = err.error;
          let message = "shared.errorPerformingTheAction";
          if (errorArray?.key === "ALREADY_INVITED") {
            message = "partner.campaignList.joinError.alreadyInvited";
          } else if (errorArray?.key === "NO_CAMPAIGN_FOUND_FOR_THIS_CODE") {
            message = "partner.campaignList.joinError.campaignNotFound";
          }
          this.notificationService.error(message);
        },
      });
  }

  protected onChangeOrder(): void {
    this.sortMode =
      this.sortMode === SortByDateMode.Asc
        ? SortByDateMode.Desc
        : SortByDateMode.Asc;

    this.getCampaigns();
  }

  protected onFilterCampaigns(filter: PartnerCampaignStatus): void {
    this.statusFilter[filter] = !this.statusFilter[filter];
    this.getCampaigns();
  }

  protected filterSelected(): boolean {
    if (!this.statusFilter) {
      return false;
    }

    for (const key of Object.keys(this.statusFilter)) {
      if (this.statusFilter[key as PartnerCampaignStatus]) {
        return true;
      }
    }

    return false;
  }

  protected onViewAllCampaigns(): Promise<void> {
    this.showFilter = false;
    this.statusFilter = {};

    Object.values(PartnerCampaignStatus).forEach((status) => {
      this.statusFilter[status] = false;
    });

    return this.getCampaigns();
  }

  protected isFilterActive(filter: PartnerCampaignStatus): boolean {
    return this.statusFilter[filter] ?? false;
  }

  private goToPartnerDashboard(campaign: PartnerCampaign): void {
    this.router.navigateByUrl(
      `${PartnerUrl.Dashboard(this.partner.id)}?startDate=` +
        dayjs(campaign.startDate).format("YYYY-MM-DD") +
        "&endDate=" +
        dayjs(campaign.endDate).format("YYYY-MM-DD"),
    );
  }
}
