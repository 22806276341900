<form
  name="contact-information-form"
  [formGroup]="form"
  (ngSubmit)="onActionSubmit()"
  novalidate
  *ngIf="partner"
>
  <div class="container">
    <section class="section">
      <div class="form-container">
        <div
          class="row"
          [ngClass]="{ 'valign-center direction-down': !isEditForm }"
        >
          <div class="col width-50 width-70-small">
            <h2 class="section-title">
              {{ "partner.edit.contactInformation" | translate }}
            </h2>
            <div class="row">
              <div class="col width-25">
                <mat-form-field *ngIf="appData.titles">
                  <mat-label>{{
                    "partner.edit.contactTitlePlaceholder" | translate
                  }}</mat-label>
                  <mat-select formControlName="title">
                    <mat-option
                      *ngFor="let contactTitle of appData.titles | sort: 'name'"
                      [value]="contactTitle.code"
                    >
                      {{ "shared.titleCode." + contactTitle.code | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col width-75">
                <mat-form-field>
                  <mat-label>{{
                    "partner.edit.contactFirstNamePlaceholder" | translate
                  }}</mat-label>
                  <input matInput formControlName="contactPersonFirstName" />
                  <mat-error>{{
                    "partner.edit.contactFirstNameRequired" | translate
                  }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <mat-form-field>
              <mat-label>{{
                "partner.edit.contactLastNamePlaceholder" | translate
              }}</mat-label>
              <input matInput formControlName="contactPersonLastName" />
              <mat-error>{{
                "partner.edit.contactLastNameRequiredLast" | translate
              }}</mat-error>
            </mat-form-field>
            <app-emails-input
              [control]="getControl('emails')"
              [label]="'partner.edit.email' | translate"
              [hint]="'partner.edit.emailsInfo' | translate"
            />
            <app-phone-input
              [appData]="appData"
              [prefixControl]="getControl('prefixPhone')"
              [phoneControl]="getControl('phone')"
            />
            <app-phone-input
              [appData]="appData"
              [prefixControl]="getControl('prefixPhoneSecond')"
              [phoneControl]="getControl('phoneSecond')"
            />
            <div class="contact-data">
              <h2 class="section-title">
                {{ "partner.edit.customerData" | translate }}
              </h2>
              <span class="section-subtitle"
                >({{ "partner.edit.customerDataInfo" | translate }})</span
              >
              <div class="customer-contact-form">
                <mat-radio-group formControlName="isContactCustomerData">
                  <div class="customer-contact-form__form-field">
                    <mat-radio-button
                      class="radio-item"
                      [value]="true"
                      title="{{
                        'partner.edit.customerDataContactPersonTitle'
                          | translate
                      }}"
                    >
                      {{ "partner.edit.customerDataContactPerson" | translate }}
                    </mat-radio-button>
                  </div>
                  <div class="customer-contact-form__form-field">
                    <mat-radio-button
                      class="radio-item"
                      [value]="false"
                      title="{{
                        'partner.edit.customerDataSendToTitle' | translate
                      }}"
                    >
                      {{ "partner.edit.customerDataSendTo" | translate }}
                      <mat-form-field class="customer-data-send-to">
                        <input
                          matInput
                          formControlName="sendCustomerDataEmail"
                          type="email"
                        />
                        @if (
                          form.get("sendCustomerDataEmail").errors?.required
                        ) {
                          <mat-error>
                            {{ "shared.emailAddressIsRequired" | translate }}
                          </mat-error>
                        } @else if (
                          form.get("sendCustomerDataEmail").errors?.email
                        ) {
                          <mat-error>
                            {{ "shared.enterAValidEmailAddress" | translate }}
                          </mat-error>
                        }
                      </mat-form-field>
                    </mat-radio-button>
                  </div>
                </mat-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="form-container">
        <div
          class="row"
          [ngClass]="{ 'valign-center direction-down': !isEditForm }"
        >
          <div class="col width-50">
            <div class="button-bar">
              <button
                class="button-previous round-button round-button--grey"
                type="button"
                (click)="onActionCancel()"
                *ngIf="!isEditForm"
              >
                {{ "shared.previous" | translate }}
              </button>
              <button
                class="button-next round-button round-button--orange"
                type="submit"
                [disabled]="isLoading || form.invalid"
                *ngIf="isEditForm"
              >
                {{ "shared.save" | translate }}
              </button>
              <button
                class="button-next round-button round-button--orange"
                type="submit"
                [disabled]="isLoading || form.invalid"
                *ngIf="!isEditForm"
              >
                {{ "shared.next" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</form>
