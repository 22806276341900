import {
  ChangeDetectionStrategy,
  Component,
  Input,
  computed,
  input,
} from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { TranslateModule } from "@ngx-translate/core";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { map } from "rxjs";
import { AppData, AppDataPrefix } from "../../models/appData";
import { LanguageService } from "../../services/language.service";
import { MatInputModule } from "@angular/material/input";

@Component({
  standalone: true,
  selector: "app-phone-input",
  templateUrl: "./phone-input.component.html",
  styleUrl: "./phone-input.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class PhoneInputComponent {
  public appData = input.required<AppData>();
  @Input({ required: true }) public prefixControl!: FormControl<string>;
  @Input({ required: true }) public phoneControl!: FormControl<string>;

  protected readonly prefixFilterControl = new FormControl("", {
    nonNullable: true,
  });

  constructor(private readonly languageService: LanguageService) {}

  protected readonly prefixFilter = toSignal(
    this.prefixFilterControl.valueChanges.pipe(
      map((value) => value.trim().toLowerCase()),
    ),
  );

  private prefixes = computed<Required<AppDataPrefix>[]>(() =>
    this.languageService.getTranslatedPrefixes(
      this.appData().countries,
      this.appData().prefixes,
      this.languageService.locale,
    ),
  );

  protected filteredPrefixes = computed<Required<AppDataPrefix>[]>(() => {
    if (!this.prefixFilter()) {
      return this.prefixes().slice();
    }

    return this.prefixes().filter((option) => {
      const complexOption = option.translatedName + " " + option.prefix;
      return complexOption.toLowerCase().includes(this.prefixFilter() ?? "");
    });
  });
}
