<div class="integration">
  <h2 class="integration-section-title">
    {{ "partner.edit.connectToFacebookTitle" | translate }}
  </h2>
  <div class="integration-box">
    <div class="integration-box-header">
      <div class="integration-box-header-icons">
        <img src="/assets/images/icons/instagram-facebook.svg" />
        <span
          class="integration-box-header-icons-title"
          *ngIf="partner.isConnectedToFB"
        >
          {{ "partner.edit.connections" | translate }}
        </span>
        <span
          class="integration-box-header-icons-title"
          *ngIf="!partner.isConnectedToFB"
        >
          {{ "partner.edit.connectAccount" | translate }}
        </span>
      </div>
      <div
        *ngIf="partner.isConnectedToFB"
        class="integration-box-header-actions"
      >
        <span
          class="integration-box-header-actions-connect"
          (click)="showFacebookConnectInfoDialog()"
        >
          <img src="/assets/images/icons/ic_pencil_edit.svg" width="22" />
        </span>
        <span
          class="integration-box-header-actions-disconnect"
          (click)="disconnectDialog()"
        >
          <img src="/assets/images/icons/ic_broken_chains.svg" width="22" />
        </span>
      </div>
    </div>
    <!-- Facebook Not Connected -->
    <div *ngIf="!partner.isConnectedToFB" class="integration-box-not-connected">
      <div
        *ngIf="connectingToFB"
        class="integration-box-not-connected-connect-bar"
      >
        <div class="integration-box-not-connected-connecting">
          {{ "partner.edit.connecting" | translate }}
        </div>
        <mat-progress-bar [color]="'accent'" mode="indeterminate">
        </mat-progress-bar>
      </div>
      <div class="integration-box-not-connected-text">
        {{ "partner.edit.connectFacebookInfo" | translate }}
      </div>
      <button
        [disabled]="connectingToFB || partner.doNotHaveFacebookPage"
        class="round-button round-button--grey integration-box-not-connected-button"
        (click)="showFacebookConnectInfoDialog(true)"
      >
        {{ "partner.edit.connectToFacebook" | translate }}
      </button>
      <div class="integration-box-not-connected-checkbox">
        <mat-checkbox
          [disabled]="connectingToFB"
          (change)="onEventSubmit()"
          name="doNotHaveFacebookPage"
          [(ngModel)]="partner.doNotHaveFacebookPage"
        >
          {{ "partner.edit.doNotHaveFacebookPage" | translate }}
        </mat-checkbox>
      </div>
    </div>
    <!-- End Of Facebook Not Connected -->
    <!-- Facebook Connected -->
    <div *ngIf="partner.isConnectedToFB" class="integration-box-facebook">
      <div class="integration-box-facebook-connected">
        <img
          src="{{ partner.facebookPagePicture }}"
          alt=""
          class="integration-box-facebook-connected-image"
        />
        <div class="integration-box-facebook-connected-page">
          <div class="integration-box-facebook-connected-page-name">
            <span *ngIf="partner.facebookPageName">{{
              partner.facebookPageName.substr(0, 15)
            }}</span>
            <span
              *ngIf="
                partner.facebookPageName && partner.facebookPageName.length > 15
              "
              >...</span
            >
          </div>
          <div class="integration-box-facebook-connected-page-followers">
            {{ partner.facebookPageLikes }}
            {{ "partner.edit.followers" | translate }}
          </div>
          <div
            *ngIf="!partner.hasSharedFacebookConnectionPartnerIds"
            (click)="showShareFacebookConnectionDialog()"
            class="integration-box-facebook-connected-page-connection-link"
          >
            {{ "partner.edit.shareConnectionLink" | translate }}
          </div>
          <div
            (click)="showShareFacebookConnectionDialog()"
            class="integration-box-facebook-connected-page-connection-link"
          >
            {{
              "partner.edit.sharedConnectionConnectedLink"
                | translate
                  : {
                      totalAccounts:
                        partner.totalSharedFacebookConnectionPartnerIds
                    }
            }}
          </div>
        </div>
      </div>
    </div>
    <!-- End Of Facebook Connected -->
    <!-- Instagram Connected -->
    <div *ngIf="partner.isConnectedToFB" class="integration-box-instagram">
      <div
        *ngIf="partner.isConnectedToInstagram"
        class="integration-box-instagram-connected"
      >
        <img
          src="{{
            partner.instagramAccountPicture ||
              '/assets/images/icons/as_instagram-user.svg'
          }}"
          alt=""
          class="integration-box-instagram-connected-image"
        />
        <div class="integration-box-instagram-connected-page">
          <div class="integration-box-instagram-connected-page-title">
            {{ "partner.edit.instagramAccount" | translate }}
          </div>
          <div class="integration-box-instagram-connected-page-name">
            {{ partner.instagramAccountName }}
          </div>
        </div>
      </div>
      <div
        *ngIf="!partner.isConnectedToInstagram"
        class="integration-box-instagram-not-connected"
      >
        <img
          src="/assets/images/facebook-connection/instagram-no-account.svg"
          class="integration-box-instagram-not-connected-image"
        />
        <div class="integration-box-instagram-not-connected-page">
          <div class="integration-box-instagram-not-connected-page-title">
            {{ "partner.edit.instagramAccount" | translate }}
          </div>
          <div class="integration-box-instagram-not-connected-page-subtitle">
            {{ "partner.edit.noInstagramAccount" | translate }}
          </div>
        </div>
      </div>
    </div>
    <!-- End Of Instagram Connected -->
    <!-- Promo Ads -->
    <div *ngIf="partner.isConnectedToFB" class="integration-box-promo-ads">
      <div
        *ngIf="!partner.isAuthorizedFBAds"
        class="integration-box-promo-ads-not-connected"
      >
        <div class="integration-box-promo-ads-not-connected-header">
          <img
            src="/assets/images/facebook-connection/set-promo-ads.svg"
            height="70"
          />
          <div class="integration-box-promo-ads-not-connected-header-title">
            {{ "partner.edit.startUsingPromoAds" | translate }}
          </div>
        </div>
        <div class="integration-box-promo-ads-not-connected-info">
          {{ "partner.edit.setupPromoAdsInfo" | translate }}
        </div>
        <button
          class="round-button round-button--grey integration-box-promo-ads-not-connected-button"
          (click)="openUnclaimedPageDialog()"
        >
          {{ "partner.edit.setupPromoAdsTitle" | translate }}
        </button>
      </div>
      <div
        *ngIf="partner.isAuthorizedFBAds"
        class="integration-box-promo-ads-connected"
      >
        <div class="integration-box-promo-ads-connected-header">
          <img
            src="/assets/images/facebook-connection/promo-ads-active.svg"
            height="70"
          />
          <div
            class="integration-box-promo-ads-connected-header-title"
            [innerHTML]="'partner.edit.promoAdsAreSet' | translate"
          ></div>
        </div>
        <div class="integration-box-promo-ads-connected-info">
          {{ "partner.edit.promoAdsOkInfo" | translate }}
        </div>
      </div>
    </div>
    <!-- End Of Promo Ads -->
  </div>
</div>
