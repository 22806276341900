<form
  name="company-files-form"
  (ngSubmit)="onActionSubmit()"
  novalidate
  *ngIf="partner"
>
  <div class="container">
    <section class="section">
      <div class="container">
        <div
          class="row"
          [ngClass]="{ 'valign-center direction-down': !isEditForm }"
        >
          <div class="col width-45 width-55-small">
            <h2 class="section-title">
              {{ "partner.edit.logosSectionTitle" | translate }}
            </h2>
            <p class="section-intro">
              {{ "partner.edit.logosSectionSubtitle" | translate }}
            </p>
            <div class="fieldset">
              <input
                type="file"
                class="file-input"
                id="logo-upload"
                #logoFileInput
                [style.display]="'none'"
                (change)="logoInputSelected($event)"
              />

              <div class="logos">
                <div *ngIf="squaredLogo" class="squared-logo">
                  <img
                    class="delete-button"
                    (click)="onDeleteLogoConfirm(squaredLogo)"
                    src="/assets/images/as_delete_logo.svg"
                  />
                  <img class="squared-image" [src]="squaredLogo.file" />
                </div>
                <div class="squared-logo-placeholder" *ngIf="!squaredLogo">
                  <span>1:1</span>
                  <span>{{
                    "partner.edit.logosSection.aspectRatio" | translate
                  }}</span>
                </div>
                <div *ngIf="landscapeLogo" class="landscape-logo">
                  <img
                    class="delete-button"
                    (click)="onDeleteLogoConfirm(landscapeLogo)"
                    src="/assets/images/as_delete_logo.svg"
                  />
                  <img class="landscape-image" [src]="landscapeLogo.file" />
                </div>
                <div class="landscape-logo-placeholder" *ngIf="!landscapeLogo">
                  <span>4:1</span>
                  <span>{{
                    "partner.edit.logosSection.aspectRatio" | translate
                  }}</span>
                </div>
              </div>

              <div class="logo-upload-wrap" *ngIf="uploadingLogo">
                <mat-progress-bar
                  [color]="'accent'"
                  class="logo-upload-progress"
                  mode="indeterminate"
                />
              </div>

              <app-file-uploader
                [uploaderTitle]="
                  'partner.edit.logosSection.dragAndDropTitle' | translate
                "
                [uploaderSubtitle]="
                  'partner.edit.logosSection.dragAndDropSubtitle'
                    | translate: { maxFileSize: MaxFileSize }
                "
                [autoUpload]="false"
                (fileAddedToQueue)="logoAddedToQueue($event)"
                [maxFileSize]="MaxFileSize"
                (uploadError)="fileUploaderErrorHandler($event)"
                [responseMapper]="fileUploaderResponseMapper"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div
          class="row"
          [ngClass]="{ 'align-center direction-down': !isEditForm }"
        >
          <div class="col width-45 width-55-small">
            <h2 class="section-title">
              {{ "partner.edit.filesSection.title" | translate }}
            </h2>
            <p class="section-intro">
              {{ "partner.edit.filesSection.subtitle" | translate }}
            </p>

            <app-file-uploader
              [uploaderTitle]="
                'partner.edit.filesSection.dragAndDropTitle' | translate
              "
              [uploaderSubtitle]="
                'partner.edit.filesSection.dragAndDropSubtitle'
                  | translate: { maxFileSize: MaxFileSize }
              "
              [uploadUrl]="uploaderFilesUrl"
              (apiResponse)="handleUploadFilesResponse($event)"
              [maxFileSize]="MaxFileSize"
              (uploadError)="fileUploaderErrorHandler($event)"
              [responseMapper]="fileUploaderResponseMapper"
              [acceptFormat]="anyFileFormat"
            />

            <!-- File List -->
            <ul class="file-list">
              <li class="file-item" *ngFor="let file of partner.files">
                <a
                  class="file-item-button"
                  type="button"
                  download="{{ file.file }}"
                  href="{{ file.file }}"
                >
                  <ng-container *ngIf="isImage(file)">
                    <span
                      class="file-item-img"
                      [ngStyle]="{
                        'background-image': 'url(' + file.file + ')'
                      }"
                    ></span>
                  </ng-container>
                  <ng-container *ngIf="!isImage(file)">
                    <span class="file-item-img file-type"></span>
                  </ng-container>
                  <span class="file-item-details">
                    <span class="file-item-name">{{ file.name }}</span>
                  </span>
                </a>
                <img
                  class="file-delete-button"
                  (click)="onDeleteFileConfirm(file)"
                  src="/assets/images/as_delete_logo.svg"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <div class="container--submit" *ngIf="!isEditForm">
      <div
        class="row"
        [ngClass]="{ 'align-center direction-down': !isEditForm }"
      >
        <div class="col width-50">
          <div class="button-bar">
            <button
              class="button-previous button-grey"
              type="button"
              (click)="onActionCancel()"
            >
              {{ "shared.previous" | translate }}
            </button>
            <button
              class="button-next button-orange next-button"
              type="submit"
              [disabled]="isLoading"
            >
              {{
                isWhiteLabel
                  ? ("shared.finish" | translate)
                  : ("shared.next" | translate)
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
